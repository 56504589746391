import React, { Component } from 'react';
import { Theme } from '@material-ui/core/styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import logo from '../logo.svg'

const styles = (theme: Theme) => ({});

interface Props extends WithStyles<typeof styles> {}

class Logo extends Component<Props> {
  render() {
    return (
      <img src={logo} alt="Throwdown Logo" />
    );
  }
}

export default withStyles(styles)(Logo);