import React, { Component } from 'react';
import { Theme } from '@material-ui/core/styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import ThrowdownAppBar from './ThrowdownAppBar';

const styles = (theme: Theme) => ({});

interface Props extends WithStyles<typeof styles> {}

class ReportResultsForm extends Component<Props> {
  render() {
    return (
        <div>
            <ThrowdownAppBar title="Report Results" />
      </div>
    );
  }
}

export default withStyles(styles)(ReportResultsForm);