import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Medal from './Medal'
import { Result, Player, Event } from './TournamentModel'
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import * as colors from '@material-ui/core/colors';


const styles = (theme: Theme) => ({
  root: {
    height: 324,
  },
  deepPurple: { backgroundColor: colors.deepPurple[500] },
  red: { backgroundColor: colors.red[500] },
  pink: { backgroundColor: colors.pink[500] },
  purple: { backgroundColor: colors.purple[500] },
  indigo: { backgroundColor: colors.indigo[500] },
  blue: { backgroundColor: colors.blue[500] },
  teal: { backgroundColor: colors.teal[500] },
  deepOrange: { backgroundColor: colors.deepOrange[500] },
  brown: { backgroundColor: colors.brown[500] },
  blueGrey: { backgroundColor: colors.blueGrey[500] }
});

type Props = WithStyles<typeof styles> & EventResultsProps

type EventResultsProps = {
  event: Event,
  players: Result[]
  selectedPlayer: string
  allPlayers: Player[]
}

class EventResults extends Component<Props> {
  render() {
    return (
      <Card>
        <CardMedia
          className={this.props.classes.root}
          image={this.props.event.cover}
          title="Pajaggle"
        />
        <CardContent>
          <Typography variant="h4" align="center" gutterBottom={true}>{this.props.event.name}</Typography>
          {/* <Typography variant="h6" align="center" gutterBottom={true}>Results</Typography> */}
          <Divider variant="middle" />
        {/* <img className={this.props.classes.root} src="https://firebasestorage.googleapis.com/v0/b/throwdown-a57d3.appspot.com/o/Volleyball.jpg?alt=media&token=08392c9e-c563-40a0-bfb1-fa1dad4e244e" /> */}
        <List>
          {this.props.players.sort((a, b) => { return a.place - b.place }).map(player =>
            <ListItem key={player.id} selected={player.name === this.props.selectedPlayer}>
              <Avatar src={player.avatar} className={this.avatarColor(player.name)}>{this.initials(player.name)}</Avatar>
              <ListItemText primary={player.name} secondary={this.formatPoints(player.place)} />
              <Medal place={player.place} />
            </ListItem>
          )}
          {this.playersWithNoResult().map(player =>
            <ListItem key={player.id} selected={player.name === this.props.selectedPlayer}>
              <Avatar src={""} className={this.avatarColor(player.name)}>{this.initials(player.name)}</Avatar>
              <ListItemText primary={player.name} secondary={this.formatPoints(0)} />
              {/* <Medal place={player.place} /> */}
            </ListItem>
          )}
        </List>
        </CardContent>
      </Card>
    );
  }

  playersWithNoResult = (): Player[] => {
    let playerNames = this.props.players.map(function (player) {
      return player.name
    });

    return this.props.allPlayers.filter(player =>
      !playerNames.includes(player.name)
    );
 } 

  handleListItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    this.setState({ selectedIndex: index });
  };

  avatarColor = (name: string): string => {
    const colorClasses: string[] = [
      this.props.classes.deepPurple,
      this.props.classes.red,
      this.props.classes.pink,
      this.props.classes.purple,
      this.props.classes.indigo,
      this.props.classes.blue,
      this.props.classes.teal,
      this.props.classes.deepOrange,
      this.props.classes.brown,
      this.props.classes.blueGrey,
    ]

    if (name.length === 0) {
      return this.props.classes.purple
    }

    const lastIndex = name.length - 1
    const lastCharCode = name.charCodeAt(lastIndex)
    const colorIndex = lastCharCode % colorClasses.length

    return colorClasses[colorIndex]
  }

  formatPoints = (place: number): string => {
    let playerCount = this.props.allPlayers.length
  
    let points = place === 0 ? 0 : playerCount - place

    if (points === 1) {
      return points + " point"
    }

    return points + " points"
  }

  initials = (name: string): string => {
    if (!name) {
      return ""
    }

    return name.charAt(0).toUpperCase()
  }
}

export default withStyles(styles)(EventResults);