import React, { Component } from 'react';
import { Theme } from '@material-ui/core/styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

const styles = (theme: Theme) => ({});

interface Props extends WithStyles<typeof styles> {
    title: string
}

class ThrowdownAppBar extends Component<Props> {
  render() {
    return (
        <AppBar position="static">
        <Toolbar>
          <IconButton color="inherit" aria-label="Menu" />
          <Typography variant="h6" color="inherit">{this.props.title}</Typography>
          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
        </AppBar>
    );
  }
}

export default withStyles(styles)(ThrowdownAppBar);
