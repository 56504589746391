import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import withRoot from './withRoot';
import Home from './components/Home';
import Leaderboard from './components/Leaderboard';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React, { Component } from 'react';
import ReportResultsForm from './components/ReportResultsForm';

const styles = (theme: Theme) => createStyles({});

interface Props extends WithStyles<typeof styles> {}

class App extends Component<Props> {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact={true} path="/" component={Home} />
          <Route path="/:code/report" component={ReportResultsForm} />
          <Route path="/:code" component={Leaderboard} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default withRoot(withStyles(styles)(App));
