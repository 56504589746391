import { Tournament, Player, Event, Result } from './TournamentModel'
import { Leaderboard } from './Leaderboard'
import firestore from './firestore';
import { resolve } from 'path';
import { reject } from 'q';

export class TournamentStore {
    tournamentRef: firestore.DocumentReference

    constructor(code: string) {
        this.tournamentRef = firestore().collection("tournaments").doc(code)
    }

    getTournament = async (): Promise<Tournament> => {
        return new Promise((resolve, reason) => {
            Promise.all([this.getName(), this.getPlayers(), this.getEvents()])
            .then(values => {
                let tournament = {
                    name: values[0],
                    players: values[1],
                    events: values[2]
                }
    
                resolve(tournament)
            })
            .catch(reason => {
                reject(reason)
            })
        })
    }

        getName = async (): Promise<string> => {
            return new Promise((resolve, reject) => {
                this.tournamentRef
                .get()
                .then(doc => {
                    let tournamentData = doc.data()
                    if(tournamentData !== undefined) {
                        console.log(tournamentData.name)
                        resolve(tournamentData.name)
                    }
                })
                .catch(reason => {
                    console.log("Error getting documents: ", reason);
                    reject(reason)
                })
            })
        }

        getPlayers = async (): Promise<Player[]> => {
            let playersRef = this.tournamentRef.collection("players")

            return new Promise((resolve, reject) => {
                playersRef
                .get()
                .then(querySnapshot => {

                    let players = querySnapshot.docs
                    .map( doc => {
                        return { id: doc.id, name: doc.data().name }
                    })
                    
                    resolve(players)
                })
                .catch(reason => {
                    console.log("Error getting documents: ", reason)
                    reject(reason)
                })
            })
        }

        getEvents = async (): Promise<Event[]> => {
            let eventsRef = this.tournamentRef.collection("events")

            return new Promise((resolve, reject) => {
                eventsRef
                .get()
                .then(querySnapshot => {
                    let resultPromises = querySnapshot.docs.map(doc => {
                        let eventDoc = eventsRef.doc(doc.id)
                        return this.getResults(eventDoc)
                    })

                    Promise.all(resultPromises).then(results => {
                        let events = querySnapshot.docs.map((doc, index) => {
            
                            let data = doc.data()

                            return { id: doc.id,
                                cover:  data.cover,
                                banner: data.banner,
                                name: data.name,
                                results: results[index]
                            }
                        })

                        resolve(events)
                    })
                })
                .catch(reason => {
                    console.log("Error getting documents: ", reason)
                    reject(reason)
                })
            })
        }
            
        // let eventSummary = this.makeSummaryEvent(newEvents, newPlayers)    
        // newEvents.unshift(eventSummary) 
        makeSummaryEvent = (events: Event[], players: Player[]): Event => {
            var allResults = events.flatMap(event => event.results)

            var summaryResult: Result[] = players
            .map(player => TournamentStore.summaryForPlayer(player, allResults))
            .sort((b, a) => a.points - b.points)

            var place = 0
            var previousPoints = Number.MAX_VALUE
            summaryResult.forEach(result => {
            if (result.points < previousPoints) {
                place++
                previousPoints = result.points
            }
            
            result.place = place
            })

            return {
              id: "0",
              banner: "https://firebasestorage.googleapis.com/v0/b/throwdown-a57d3.appspot.com/o/All_Events.jpg?alt=media&token=608bfc0c-292f-4984-9b0b-1f3c53df2f0f",
              cover: "https://firebasestorage.googleapis.com/v0/b/throwdown-a57d3.appspot.com/o/all-events-icon.svg?alt=media&token=9385718f-c035-4150-a944-e5d25eef4cd0",
              name: "All Events",
              results: summaryResult
            }
        }

        getResults = async (eventDoc: firestore.DocumentReference): Promise<Result[]> => {
            let resultsRef = eventDoc.collection("results")

            return new Promise((resolve, reject) => {
                resultsRef.get()
                .then(querySnapshot => {
                    let results = querySnapshot.docs.map(doc => {

                        let resultsData = doc.data()
                        return { id: doc.id,
                            avatar: "",
                            place: resultsData.place,
                            points: resultsData.points,
                            name: resultsData.player.name
                        }
                    })
console.log(results)
                    resolve(results)
                })
                .catch(reason => {
                    reject(reason)
                })
            })

            // eventDoc.collection("results")
            // .get()
            // .then(querySnapshot => {
            //     var results: Result[] = []

            //     querySnapshot.forEach(resultsDoc => {
            //         console.log(resultsDoc.id, " => ", resultsDoc.data());
            //         let resultsData = resultsDoc.data()

            //         results.push({
            //             id: resultsDoc.id,
            //             avatar: "",
            //             place: resultsData.place,
            //             points: resultsData.points,
            //             name: resultsData.player.name
            //         })
            //     })
                

            //     return results
            // })

            // return []
        }
    

    static getTotalPoints = (results: Result[], playerName: string) => {
        return results
        .filter(result => result.name === playerName)
        .map(result => result.points)
        .reduce((subTotal, points) => subTotal + points, 0)
    }

    static summaryForPlayer = (player: Player, allResults: Result[]): Result => {
        var playerSummaryResult: Result = {
        id: player.id,
        name: player.name,
        points: TournamentStore.getTotalPoints(allResults, player.name),
        avatar: "",
        place: 0
        }

        return playerSummaryResult
    }
}