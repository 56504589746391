import React, { Component } from 'react';
import { Theme } from '@material-ui/core/styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Events from './Events';
import Players from './Players';
import ReportResultsButton from './ReportResultsButton';
import Hidden from '@material-ui/core/Hidden';
import ThrowdownAppBar from './ThrowdownAppBar';
import { TournamentStore } from './ThrowdownStore';
import { Player, Event } from './TournamentModel';

interface MatchParams {
  code: string;
}

const styles = (theme: Theme) => ({
  root: {
    marginTop: 16
  }
});

type Props = WithStyles<typeof styles> & RouteComponentProps<MatchParams>

export type RouteComponentProps<P> = {
  match: match<P>;
  // location: H.Location;
  // history: H.History;
  staticContext?: any;
}

export type match<P> = {
  params: P;
  isExact: boolean;
  path: string;
  url: string;
}

type State = {
  name: string,
  events: Event[],
  players: Player[],
  selectedIndex: number
}

export class Leaderboard extends Component<Props, State> {
    state: State = {
      name: "Loading",
      players: [],
      events: [
        {
          id: "0",
          cover: "https://firebasestorage.googleapis.com/v0/b/throwdown-a57d3.appspot.com/o/all-events-icon.svg?alt=media&token=9385718f-c035-4150-a944-e5d25eef4cd0",
          banner: "",
          name: "All Events",
          results: []
        },
      ],
      selectedIndex: 0
    }

    render() {
      return (
        <div>
        <ThrowdownAppBar title={this.state.name} />
        <Grid container justify="center" spacing={24} className={this.props.classes.root}>
          <Hidden smDown>
            <Grid item xl={3} md={4}>
              <Events events={this.state.events} selectedIndex={this.state.selectedIndex} onChange={this.handleEventChange} />
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item xs={11}>
              <Events events={this.state.events} selectedIndex={this.state.selectedIndex} onChange={this.handleEventChange} />
            </Grid>
          </Hidden>
          <Grid item xl={4} md={6} xs={11}>
              <Players players={this.state.events[this.state.selectedIndex].results} allPlayers={this.state.players} selectedPlayer="" event={this.state.events[this.state.selectedIndex]} />
          </Grid>
          {/* <ReportResultsButton /> */}
        </Grid>                                                                                        
        </div>
      );
    }

    componentDidMount() {
      new TournamentStore(this.props.match.params.code).getTournament().then(tournament => {
        this.setState({
          name: tournament.name,
          players: tournament.players,
          events: tournament.events
        })
      })
    }

    handleEventChange = (index: number) => {
      this.setState({selectedIndex: index});
    }
}

export default withStyles(styles)(Leaderboard);