import firebase, { firestore } from 'firebase';

var config = {
    apiKey: "AIzaSyDgiodJs0MNtQ8erILmtDJSc7PG3vkvmts",
    authDomain: "throwdown-a57d3.firebaseapp.com",
    databaseURL: "https://throwdown-a57d3.firebaseio.com",
    projectId: "throwdown-a57d3",
    storageBucket: "throwdown-a57d3.appspot.com",
    messagingSenderId: "1077080753475"
};

firebase.initializeApp(config);

export default firestore;