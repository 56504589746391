import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Event } from './TournamentModel'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core/styles';
import allEventsIcon from './all-events-icon.svg';

const styles = (theme: Theme) => ({
  paper: {
    textAlign: 'center' as 'center',
    margin: 48,
    padding: 32
  },
  cover: {
    borderRadius: '8px',
    // width: 50,
    height: 50
  }
});

type Props = WithStyles<typeof styles>
& {
  events: Event[],
  selectedIndex: number,
  onChange: (index: number) => void
}

class Events extends Component<Props> {
  render() {
    return (
      <Paper>
      <List component="nav">
          {this.props.events.map((event, index) =>
            <ListItem
            key={event.id}
            selected={this.props.selectedIndex === index}
            onClick={event => this.handleListItemClick(event, index)}
            >
              <img src={event.cover} className={this.props.classes.cover} />
              <ListItemText primary={event.name} />
            </ListItem>
          )}
        </List>
      </Paper>
    );
  }

  handleListItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    this.props.onChange(index)
  };
}

export default withStyles(styles)(Events);