import React, { Component, ChangeEvent, FormEvent } from 'react';
import { Theme } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Button from "@material-ui/core/Button";
import { Redirect } from 'react-router';

const styles = (theme: Theme) => ({
  button: {
    display: 'block',
    margin: 'auto'
  }
});

type Props = WithStyles<typeof styles>

type State = typeof initialState

const initialState = {
  code: "",
  gotoTournament: false
}

class TournamentCodeForm extends Component<Props, State> {
  state = initialState

  render() {
    return (
      <form onSubmit={this.handleSubmit} noValidate autoComplete="off">
        <TextField
          id="tournament-code"
          label="Tournament Code"
          inputProps={{className: 'uppercase-text'}}
          value={this.state.code}
          onChange={this.handleChange}
          margin="normal"
        />
        <Button
          className={this.props.classes.button}
          disabled={this.state.code.length === 0}
          variant="contained"
          color="primary"
          type="submit">
          Join Tournament
        </Button>
        {this.state.gotoTournament && (
          <Redirect push to={this.state.code}/>
        )}
      </form>
    );
  }

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      code: event.currentTarget.value.toUpperCase()
    })
  }

  handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()

    this.setState({
      gotoTournament: true
    })
  }
}

export default withStyles(styles)(TournamentCodeForm);