import React, { Component } from 'react';
import { Grid, Theme, WithStyles, Paper } from '@material-ui/core';
import Logo from './Logo';
import TournamentCodeForm from './TournamentCodeForm';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme: Theme) => ({
  paper: {
    textAlign: 'center' as 'center',
    margin: 48,
    padding: 32
  }
});

type Props = WithStyles<typeof styles>

class Home extends Component<Props> {
  render() {
    return (
      <Grid container justify="center" spacing={24}>
        <Grid item lg={4}>
          <Paper className={this.props.classes.paper} elevation={1}>
            <Logo />
            <TournamentCodeForm />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Home);