import React, { Component } from 'react';
import { Theme } from '@material-ui/core/styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

type Props = WithStyles<typeof styles> & { place: number }

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: '#58397F',
    padding: '2px 8px 4px 8px',
    borderRadius: 25,
    color: '#FFFFFF',
    fontWeight: 600,
    width: 40,
    textAlign: 'center' as 'center',
    fontSize: 16,
  },

  first: { backgroundImage: "linear-gradient(-180deg, #FBD249 0%, #F5A623 100%)" },
  second: { backgroundImage: "linear-gradient(-180deg, #C5C0B2 3%, #837E76 100%)" },
  third: { backgroundImage: "linear-gradient(-180deg, #B96F4C 0%, #45291B 100%)" },

  sup: {
    position: 'relative' as 'relative',
    top: 2,
    fontSize: 12
  }
});

class Medal extends Component<Props> {
  render() {
    return (
      <div className={`${this.props.classes.root} ${this.className(this.props.place)}`}>
        {this.props.place}<sup className={this.props.classes.sup}>{this.formatPlace(this.props.place)}</sup>
      </div>
    );
  }

  className = (place: number): string => {
    if (place === 1) {
      return this.props.classes.first
    }

    if (place === 2) {
      return this.props.classes.second
    }

    if (place === 3) {
      return this.props.classes.third
    }

    return ""
  }

  formatPlace = (place: number): string => {
      var lastDigit = place % 10,
          lastTwoDigits = place % 100;

      if (lastDigit == 1 && lastTwoDigits != 11) {
          return "st";
      }

      if (lastDigit == 2 && lastTwoDigits != 12) {
          return "nd";
      }

      if (lastDigit == 3 && lastTwoDigits != 13) {
          return "rd";
      }

      return "th";
  }
}

export default withStyles(styles)(Medal);